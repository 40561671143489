import { template as template_b72f8126f26a475f8c5d33823e92e21f } from "@ember/template-compiler";
const FKControlMenuContainer = template_b72f8126f26a475f8c5d33823e92e21f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
