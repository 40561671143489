import { template as template_dfa6b955bd2c4c57be1b7317dcfa7278 } from "@ember/template-compiler";
const FKLabel = template_dfa6b955bd2c4c57be1b7317dcfa7278(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
